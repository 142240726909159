































import { Vue, Component, Watch } from 'vue-property-decorator';
import Page from '@/layouts/Page.vue';
import BannerTable from '@/components/custom-events/BannerTable.vue';
import EmptyPlaceholder from '@/components/common/EmptyPlaceholder.vue';
import { CustomEventDetails } from '@/models/custom-event';
import { BannerStores } from '@/store/banners';
import { PaginationDetails } from '@/models/generic';
import PaginationControls from '@/components/common/PaginationControls.vue';
import SearchBar from '@/components/common/SearchBar.vue';

@Component({
  components: {
    Page,
    BannerTable,
    EmptyPlaceholder,
    PaginationControls,
    SearchBar,
  },
})
export default class BannerManagementPage extends Vue {
  bannerStore = BannerStores;

  currentPage = 1;
  currentLimit = 10;
  searchText = '';

  get banners() {
    return this.bannerStore?.banners;
  }

  mounted() {
    this.fetchData();
  }

  fetchData() {
    this.bannerStore.getBanners({
      page: this.currentPage,
      limit: this.currentLimit,
      keyword: this.searchText,
    });
  }

  onChangePage(params: PaginationDetails) {
    this.currentPage = params.page;
    this.currentLimit = params.limit;
    this.fetchData();
  }

  handleSearch(keyword: string) {
    this.searchText = keyword;
    this.fetchData();
  }

  handleEdit(data: CustomEventDetails) {
    this.$router.push({ path: `banners/${data.id}/edit` });
  }

  handleDelete(data: CustomEventDetails) {
    this.$buefy.dialog.confirm({
      title: 'Confirm Delete',
      message: `Are you sure you want to delete <b>${data.name}</b>?`,
      onConfirm: () => {
        this.bannerStore.deleteBanner(data.id);
      },
    });
  }

  @Watch('bannerStore.isDeletingBanner')
  onisDeletingBannerChange(isDeletingBanner: boolean) {
    if (!isDeletingBanner) {
      this.fetchData();
    }
  }
}
